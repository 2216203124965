import React from "react";
import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";
import { themeOptions } from "../PatientEnrollment/ThemeOptionsPatientEnrollment";
import rtmBanner from "./assets/rtm_banner.png";
import { useNavigate } from "react-router-dom";
import {
  ThemeProvider,
  Button,
  FormControlLabel,
  Grid,
  Checkbox,
  FormGroup,
  FormHelperText,
  Typography,
  CardMedia,
} from "@mui/material";
import jwt from "jsonwebtoken";
import InputMask from "react-input-mask";
import { patientSchema } from "../RtmConsent/PatientValidationSchema.ts";
import Box from "@mui/material/Box";
import { PUBLIC_KEY } from "../../app/constants";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import BeatLoader from "react-spinners/BeatLoader";
import { markPatientConsent } from "./RtmConsentAPI.ts";

export default function PatientConsent(props) {
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState("");
  const [patientId, setPatientId] = useState(0);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm({ resolver: yupResolver(patientSchema) });

  useEffect(() => {
    try {
      const token = searchParams.get("token");
      const decodedToken = jwt.verify(token, PUBLIC_KEY, {
        algorithms: ["RS256"],
      });
      setToken(token);
      setPatientId(decodedToken.patient_id);
      reset({
        first_name: decodedToken.patient_first_name,
        last_name: decodedToken.patient_last_name,
        date_of_birth: decodedToken.patient_dob,
        phone_number: decodedToken.patient_phone_number,
        email: Boolean(decodedToken.patient_email)
          ? decodedToken.patient_email
          : undefined,
      });
    } catch (e) {
      console.log("No valid token");
    }
  }, [searchParams, props.update, reset]);

  const watchFirstName = watch("first_name");
  const watchLastName = watch("last_name");

  const theme = createTheme(themeOptions);
  const onSubmit = async (data) => {
    setIsLoadingResponse(true);
    try {
      const resp = await markPatientConsent(
        patientId,
        data.phone_number,
        data.email,
        data.patientConsent,
      );

      console.log(resp.data);

      setIsLoadingResponse(false);
      if (resp.data.patient_consent_validated) {
        navigate("/rtm_consent/confirmation", {
          state: {
            id: patientId,
            consented: true,
            token: token,
          },
        });
      } else {
        navigate("/rtm_consent/confirmation", {
          state: {
            id: patientId,
            consented: false,
            token: token,
          },
        });
      }
    } catch (err) {
      setIsLoadingResponse(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CardMedia
        sx={{ width: 1, height: 1 / 5 }}
        src={rtmBanner}
        component="img"
      />
      <Box sx={{ mx: 8, mb: 8, mt: 2 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            style={{ minHeight: "40vh" }}
            alignItems="center"
            justifyContent="flex-start"
            container
            direction="row"
            align="stretch"
            spacing={2}
          >
            <Grid item xs={12}>
              <Box sx={{ display: "flex" }}>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Hello, &nbsp;
                </Typography>
                <Typography variant="body1">
                  {watchFirstName} {watchLastName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">01 Patient Info</Typography>
            </Grid>
            <Grid item xs={6} md={4} container flexGrow>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Patient First Name"
                    sx={{ flex: 1 }}
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} container flexGrow>
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ flex: 1 }}
                    label={"Patient Last Name"}
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} container flexGrow>
              <Controller
                name="date_of_birth"
                control={control}
                defaultValue={moment()}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label="Patient Date of Birth"
                    sx={{ flex: 1 }}
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={6} container flexGrow>
              <Controller
                name="phone_number"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <InputMask
                    mask="9999999999"
                    value={value}
                    onChange={onChange}
                  >
                    {(inputProps) => (
                      <TextField
                        label="Patient Phone Number"
                        type="text"
                        inputRef={ref}
                        sx={{ flex: 1 }}
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
              {errors.phone_number && (
                <FormHelperText error>
                  {errors.phone_number.message}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6} md={6} container flexGrow>
              <Controller
                name="email"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label={"Patient Email"}
                    inputRef={ref}
                    sx={{ flex: 1 }}
                    inputProps={{ maxLength: 80 }}
                  />
                )}
              />
              {errors.email && (
                <FormHelperText error>{errors.email.message}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">02 Patient Consent</Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="patient_consent"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormGroup>
                    <FormControlLabel
                      sx={{ alignItems: "flex-start" }}
                      control={
                        <Checkbox
                          {...field}
                          sx={{
                            marginTop: -1,
                          }}
                        />
                      }
                      label={
                        <p>
                          By clicking 'Submit,' I, {watchFirstName}{" "}
                          {watchLastName}, consent to receive remote monitoring
                          services provided by Athelas under the direction and
                          supervision of my healthcare provider. I consent to
                          the terms set forth in the{" "}
                          <a href="https://drive.google.com/file/d/1_ujVn3csDd6tnZ2aYe3dEIQ5oQkL9g3A/view?usp=sharing">
                            patient consent
                          </a>
                          . Depending on your insurance, there may be a monthly
                          copay associated with enrollment in this program. I
                          agree to receive communications by text message.
                          Standard message rates may apply. You can reply STOP
                          to opt-out or HELP for more information. You may
                          review our{" "}
                          <a href="https://www.athelas.com/privacy-policy">
                            Privacy Policy
                          </a>{" "}
                          to learn how your data is used.
                        </p>
                      }
                    />
                    {errors.patient_consent && (
                      <FormHelperText error>
                        {errors.patient_consent.message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                )}
              />
            </Grid>
            {isLoadingResponse && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <BeatLoader color="#E85B81" />
                <p>Verifying Information</p>
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                type="submit"
                disabled={isLoadingResponse}
                style={{ minWidth: "30vw", textAlign: "center" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </ThemeProvider>
  );
}
