import React, { useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import PatientInfoWrapper from "./features/patientInfo/PatientInfoWrapper";
import AdditionalQuestions from "./features/additionalQuestions/AdditionalQuestions";
import Footer from "./components/Footer/Footer";
import Login from "./features/login/Login";
import CareCampaign from "./features/careCampaign/CareCampaign";

import EliteDNALogo from "./assets/elite-dna.png";
import { TotalCare } from "./features/totalCare/TotalCare";

import Header from "./components/Header/Header";
import Steps from "./components/Steps/Steps";
import Faq from "./components/Faq/Faq";
import Confirmation from "./components/Confirmation/Confirmation";
import Failure from "./components/Failure/Failure";
import Gem from "./features/gem/Gem";
import PillTrackOnboarding from "./features/gemOnboarding/PillTrackOnboarding";
import {
  gemOnboardingQuestions,
  onboardingQuestions,
  totalCareOnboardingQuestions,
  pilltrackSurveyQuestions,
} from "./questions";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { TotalCareReport } from "./features/totalCare/TotalCareReport";
import { TotalCareOnboarding } from "./features/totalCareOnboarding/totalCareOnboarding";
import NurseProgram from "./components/NurseProgram/NurseProgram";
import PilltrackSurvey from "./features/PilltrackSurvey/PilltrackSurvey";
import { CustomizedPage } from "./features/revampedOnboarding/CustomizedPage";
import { PartnershipEmbed } from "./features/PartnershipEmbed/PartnershipEmbedPage";
import { trackEvent } from "./app/utils/tracking";
import PillTrackEnrollment from "./features/PillTrackEnrollment/PillTrackEnrollment";
import PatientEnrollmentRouter from "./features/PatientEnrollment/PatientEnrollmentRouter";
import Unsubscribe from "./features/unsubscribe/Unsubscribe";
import AthelasOneOnboarding from "./features/AthelasOneOnboarding/AthelasOneOnboarding";
import PatientPayRouter from "./features/PatientPay/PatientPayRouter";
import GgpPatientApplicationRouter from "./features/GgpPatientApplication/GgpPatientApplicationRouter";
import AthelasHomeOnboarding from "./features/AthelasHomeOnboarding/AthelasHomeOnboarding";
import RtmConsentRouter from "./features/RtmConsent/RtmConsentRouter";

function App() {
  const [trackedPageLoad, setTrackedPageLoad] = useState(false);

  if (!trackedPageLoad) {
    trackEvent("view", "page_load", {
      link: window.location.pathname,
    });
    setTrackedPageLoad(true);
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <div className="App">
              <Header />
              <Login />
              <Steps />
              <NurseProgram />
              <Faq questions={onboardingQuestions} />
              <Footer />
            </div>
          }
        />
        <Route
          exact
          path="/prescriberOnboardPatient"
          element={
            <div className="App">
              <Header />
              <Login doctor_enrollment={true} />
              <Steps />
              <NurseProgram />
              <Faq questions={onboardingQuestions} />
              <Footer />
            </div>
          }
        />
        <Route
          exact
          path="/elite-dna"
          element={
            <div className="App">
              <Header />
              <Login
                practiceLogo={EliteDNALogo}
                practiceName={"Elite DNA Therapy Services"}
              />
              <Steps />
              <NurseProgram />
              <Faq questions={onboardingQuestions} />
              <Footer />
            </div>
          }
        />
        <Route exact path="/embed/" element={<CustomizedPage />} />
        <Route exact path="/embed/:slug" element={<CustomizedPage />} />
        <Route exact path="/p/" element={<PartnershipEmbed />} />
        <Route exact path="/p/:slug" element={<PartnershipEmbed />} />
        <Route
          path="/tco"
          element={
            <div className="App">
              <Header />
              <TotalCareOnboarding />
              <Steps />
              <Faq questions={totalCareOnboardingQuestions} />
            </div>
          }
        />
        <Route
          path="/updatePatientInfo"
          element={
            <div className="App">
              <Header />
              <PatientInfoWrapper />
              <Steps />
              <Faq questions={onboardingQuestions} />
              <Footer />
            </div>
          }
        />
        <Route
          path="/additionalQuestions"
          element={
            <div className="App">
              <Header />
              <AdditionalQuestions />
              <Steps />
              <Faq questions={onboardingQuestions} />
              <Footer />
            </div>
          }
        />
        <Route
          path="/confirm"
          element={
            <div className="App">
              <Header />
              <Confirmation />
              <Steps />
              <Faq questions={onboardingQuestions} />
            </div>
          }
        />
        <Route
          path="/failure"
          element={
            <div className="App">
              <Failure />
              <Steps />
              <Faq questions={onboardingQuestions} />
              <Footer />
            </div>
          }
        />
        <Route
          path="/doctorflow"
          element={
            <div className="App">
              <Header />
              <PatientInfoWrapper doctor_onboarding={true} />
              <Steps />
              <Faq questions={onboardingQuestions} />
              <Footer />
            </div>
          }
        />
        <Route
          path="/careCampaign"
          element={
            <div className="App">
              <Header />
              <CareCampaign />
              <Footer />
            </div>
          }
        />
        <Route
          path="/gem"
          element={
            <div className="App">
              <Header />
              <Gem />
              <Faq questions={gemOnboardingQuestions} />
              <Footer />
            </div>
          }
        />
        <Route
          path="/pilltracksurvey"
          element={
            <div className="App">
              <Header />
              <PilltrackSurvey />
              <Faq questions={pilltrackSurveyQuestions} />
              <Footer />
            </div>
          }
        />
        <Route
          path="/patient_enrollment/*"
          element={
            <div>
              <PatientEnrollmentRouter update={false} />
            </div>
          }
        />
        <Route
          path="/ggp_patient_application/*"
          element={
            <div>
              <GgpPatientApplicationRouter/>
            </div>
          }
        />
        <Route
          path="/rtm_consent/*"
          element={
            <div>
              <RtmConsentRouter/>
            </div>
          }
        />
        <Route
          path="/patient_update/*"
          element={
            <div>
              <PatientEnrollmentRouter update={true} />
            </div>
          }
        />
        <Route
          path="/pilltrack_enrollment"
          element={
            <div>
              <Header />
              <PillTrackEnrollment />
            </div>
          }
        />
        <Route
          path="/athelas_one"
          element={
            <div>
              <Header />
              <AthelasOneOnboarding />
            </div>
          }
        />
        <Route
          path="/athelas_home"
          element={
            <div>
              <Header />
              <AthelasHomeOnboarding />
            </div>
          }
        />
        <Route path="/pilltrack/*" element={<PillTrackOnboarding />} />
        <Route
          path="/totalCare"
          element={
            <div className="App">
              <TotalCare />
              <Faq questions={totalCareOnboardingQuestions} />
            </div>
          }
        />
        <Route
          path="/totalCareReport"
          element={
            <div className="App">
              <TotalCareReport /> <Footer />
            </div>
          }
        />
        <Route
          exact
          path="/unsubscribe"
          element={
            <div className="App">
              <Header />
              <Unsubscribe />
              <Footer />
            </div>
          }
        />
        <Route
          path="/patient_pay/*"
          element={
            <div>
              <PatientPayRouter />
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
