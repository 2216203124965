import { object, string, boolean, InferType, mixed, array, date } from "yup";

const requiredMessage = "This field is required."
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


export const patientSchema = object({
    patient_consent: boolean()
        .required(requiredMessage)
        .oneOf([true], "You must agree to the patient consent terms"),
    email: string().required(requiredMessage).email("Please enter a valid email address"),
    phone_number: string().required(requiredMessage).matches(phoneRegExp, "Phone number is not valid"),
})