import axios from "axios";
import { API_SERVER_URL } from "../../app/constants";

export const markPatientConsent = (
    patient_id: number,
    phone_number: string,
    email: string,
) => {
    return axios.post(
        `${API_SERVER_URL}/patients/${patient_id}/mark_rtm_consent`,
        {
            phone_number: phone_number,
            email: email,
        }
    )
}