import { useEffect, useState } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import RtmConsent from "./RtmConsent";
import RtmConsentConfirmation from "./RtmConsentConfirmation";

function RtmConsentRouter(props) {
  let [searchParams] = useSearchParams();
  let [token, setToken] = useState("");

  useEffect(() => {
    try {
      const token = searchParams.get("token");
      setToken(token);
    } catch (e) {
      console.log("No patient enrollment token");
    }
  }, [searchParams]);

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<RtmConsent/>}
      />
      <Route
        path="confirmation"
        element={<RtmConsentConfirmation token={token} />}
      />
    </Routes>
  );
}
export default RtmConsentRouter;
