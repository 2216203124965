import React from "react";
import { createTheme } from "@mui/material/styles";
import { themeOptions } from "../PatientEnrollment/ThemeOptionsPatientEnrollment";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ThemeProvider,
  Typography,
  Grid,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";

export default function RtmConsentConfirmation() {
  const theme = createTheme(themeOptions);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {location.state?.consented ? (
              <>
                <CheckIcon sx={{ fontSize: 40, color: "#e85b81" }}></CheckIcon>
                <Typography align="center" variant="h5" gutterBottom>
                  Thank you for signing up for the Remote Therapy Management
                  program. Please reach out to support@athelas.com if you have
                  any questions.
                </Typography>
              </>
            ) : (
              <Typography align="center" variant="h5" gutterBottom>
                Sorry, there was an issue with the form. Please reach out to
                support@athelas.com.
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
